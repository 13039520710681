import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import App from 'src/App';
import { Auth0ProviderWithNavigate } from 'src/contexts/Auth0';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import './styles/index.css';
import { AppProvider } from './utils/AppContextUtils';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

ReactDOM.render(
  <HelmetProvider>
    <AppProvider>
      <Provider store={store}>
        <SidebarProvider>
          <BrowserRouter>
            <ScrollTop />
            <Auth0ProviderWithNavigate>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </SidebarProvider>
      </Provider>
    </AppProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
