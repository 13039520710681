export const rfpNameSchema = [
  {
    key: 'description',
    label: 'Description of project'
  },
  {
    key: 'additional_information',
    label: 'Additional Information'
  },
  {
    key: 'complexity',
    label: 'Complexity of project'
  },
  {
    key: 'objective',
    label: 'Objective in relation to project'
  },
  {
    key: 'jurisdiction',
    label: 'Jurisdiction/Language'
  },
  {
    key: 'governing_law',
    label: 'Governing law of main legal documentation (if known/agreed)'
  },
  {
    key: 'document_language',
    label: 'Language of legal documentation'
  },
  {
    key: 'correspondence_language',
    label: 'Preferred language of correspondence between legal services provider and us'
  },
  {
    key: 'participate_dt',
    label: 'We request that you confirm your intention to participate or not in this RFP by'
  },
  {
    key: 'pre_agreed',
    label: 'Terms & Conditions already pre-agreed between corporate and invited legal services providers'
  },
  {
    key: 'not_pre_agreed',
    label: 'If not pre-agreed for one or more of the invited legal services providers'
  },
  {
    key: 'lsp_terms_condition',
    label: 'Invited legal services providers terms & conditions to apply (to be uploaded with their fee proposal)'
  },
  {
    key: 'external_terms_condition',
    label: 'Terms & Conditions to be agreed with winning bidder outside of Zaven platform'
  },
  {
    key: 'our_terms_condition',
    label: 'Our terms & conditions to apply (including billing policy)'
  },
  {
    key: 'zaven_terms_condition',
    label: 'Zaven middle of the road terms & conditions to apply'
  },
  {
    key: 'legal_advice',
    label: 'Legal advice'
  },
  {
    key: 'contract_drafting',
    label: 'Contract drafting'
  },
  {
    key: 'contract_review',
    label: 'Contract review'
  },
  {
    key: 'negotiation',
    label: 'Negotiation'
  },
  {
    key: 'regulatory',
    label: 'Regulatory'
  },
  {
    key: 'key_team_members',
    label: 'Key team members (title, role, years of experience, project role) Location of Lead partner'
  },
  {
    key: 'add_biography',
    label: 'Biographies'
  },
  {
    key: 'staffing',
    label: 'Staffing and project management'
  },
  //
  {
    key: 'diversity',
    label: 'Diversity, Equity & Inclusion (DEI)'
  },
  {
    key: '',
    label: 'Team lead must meet above DEI requirement'
  },
  {
    key: 'minority_lead',
    label: 'Key team members: at least'
  },
  {
    key: 'minority_percentage',
    label: 'must meet DEI requirement'
  },
  {
    key: 'fee_proposal_presentation',
    label: 'Presentation of fee proposals'
  },
  {
    key: 'additional_info',
    label: 'Additional Information - Value'
  },
  {
    key: 'credentials',
    label: 'Credentials (by order of relevance/most recent first)'
  },
  {
    key: 'disaggregation',
    label: 'Disaggregation of services (outsourcing and/or (legal) technology related)'
  },
  {
    key: 'technology',
    label: 'Technology'
  },
  {
    key: 'description',
    label: 'Description/name of technology and objective'
  },
  {
    key: 'bidders_name',
    label: 'Name of service provider(s) and scope of work'
  },
  {
    key: 'expected_efficiency',
    label: 'Expected efficiency/client monetary savings'
  },
  {
    key: 'location_lead_member',
    label: 'Location of lead partner/member'
  },
  {
    key: 'lead_partner_member',
    label: 'Lead Partner/Member'
  },
  {
    key: 'usp',
    label: 'What is your USP (max 300 characters) ?'
  },
  {
    key: 'additional_info',
    label: 'Request for additional information'
  },
  {
    key: 'sufficient_info',
    label: "We believe information provided in RFP to be sufficient to provide fee proposal and won't entertain questions by email or any request for a call"
  },
  {
    key: 'clarification_medium',
    label: 'We will entertain requests for clarification by'
  },
  {
    key: 'email_questions',
    label: 'SOW Email'
  },
  {
    key: 'email_video',
    label: 'video/audio conference'
  },
  {
    key: 'both',
    label: 'both'
  },
  {
    key: 'fee_proposal_presentation',
    label: 'Presentation of fee proposals'
  },
  {
    key: 'call_bidders',
    label: 'We will organise meeting/call with (select) bidders to present fee proposal and answer questions'
  },
  {
    key: 'video',
    label: 'OtherKeyInfo Video'
  },
  {
    key: 'provide_video',
    label: 'Legal service providers to provide short video in addition to information in this section “Other key information” (max 2 min)'
  },
  // {
  //   key: 'other',
  //   label: 'Other'
  // }
  //Pricing
  { label: 'Currency', key: 'currency' },
  { label: 'Total Fee', key: 'total_fee' },
  { label: 'Estimate', key: 'estimate' },
  { label: 'Fixed Fee', key: 'fixed_fee' },
  { label: 'Capped Fee', key: 'capped_fee' },
  { label: 'Hourly Rate Fee', key: 'hourly_rate_fee' },
  { label: 'Blended Fee', key: 'blended_fee' },
  { label: 'Contingent Fee', key: 'contingent_fee' },
  { label: 'Abort Discount', key: 'abort_discount' },
  { label: 'Success Fee', key: 'success_fee' },
  { label: 'Reverse Auction (To be conducted by Corporate outside of Zaven)', key: 'reverse_auction' },
  { label: 'Free initial advice up to a certain amount', key: 'free_advise' },
  { label: 'Retainer (general/project based)', key: 'retainer' },
  { label: 'Budget for project', key: 'budget' },
  { label: 'Pricing Other', key: 'other' },
  {
    label: 'if expenses not covered in T&Cs agreed/to be agreed between Corporate and winning bidder, consider the following',
    key: 'expenses_not_covered_in_tc'
  },
  { label: 'Percentage of total fee', key: 'percentage_of_total_fee', parent: 'expenses_not_covered_in_tc' },
  { label: 'Included in services fee', key: 'included_in_services_fee', parent: 'expenses_not_covered_in_tc' },
  { label: 'Admin only (printing, etc)', key: 'admin_only', parent: 'expenses_not_covered_in_tc' },
  { label: 'Expenses Other', key: 'other', parent: 'expenses_not_covered_in_tc' },
  { label: 'Same As Client', key: 'same_as_client', parent: 'travel_hotel_categories' },
  { label: 'Travel Class', key: 'travel_class', parent: 'travel_hotel_categories' },
  { label: 'Hotel', key: 'hotel', parent: 'travel_hotel_categories' },
  { label: 'Bidders to indicate any applicable tax to fees and %', key: 'taxes' },
  { label: 'Bidders shall not add any additional assumption & exclusion in their fee proposal', key: 'not_add_assumption' },
  { label: 'Bidders can add additional assumptions & exclusions in their fee proposal', key: 'add_assumption' },
  { label: 'Not Add Assumption', key: 'not_add_assumption' },
  { label: 'Add Assumption', key: 'add_assumption' },
  { label: 'Expected completion of project from date of instruction (in weeks)', key: 'completion_duration' },
  { label: 'Email', key: 'email', parent: 'email' },
  { label: 'Memo', key: 'memo' },
  { label: 'Power Point slides', key: 'slides' },
  { label: 'Legal Opinion', key: 'legal_opinion' },
  { label: 'legal documents drafting', key: 'document_drafting' },
  { label: 'Comments Pricing', key: 'comment', child: 'comment' },
  { label: 'Following Jurisdiction/Language explicitly excluded from scope of work', key: 'legal_jurisdictions' },
  { label: 'Legal service provider to select, engage and coordinate work of any local legal counsel(s)', key: 'bidders_select_counsel' },
  { label: 'We will select, engage and coordinate work of any local legal counsel(s)', key: 'corporate_select_counsel' },
  { label: 'Tax advice is excluded', key: 'tax_advise_exclude' },
  { label: 'No travel expected', key: 'no_travel_expected' },
  { label: 'OtherKeyInfo Other', key: 'other' },
  { label: 'Additional Information', key: '' },

  // Scope of work
  //commercial content
  {
    label: 'Legal advice',
    key: 'legal_advice'
  },
  {
    label: 'Contract drafting',
    key: 'contract_drafting'
  },
  {
    label: 'Contract review',
    key: 'contract_review'
  },
  {
    label: 'Negotiation',
    key: 'negotiation'
  },
  {
    label: 'Regulatory',
    key: 'regulatory'
  },
  {
    label: 'commercial Content othe',
    key: 'other'
  },
  // Data protection Privacy

  {
    label: 'Data protection compliance programme',
    key: 'compliance_programme'
  },
  {
    label: 'Issues for new processing system / arrangement',
    key: 'processing_system'
  },
  {
    label: 'Review data protection policies',
    key: 'review_policy'
  },
  {
    label: 'Localise data protection programme to new jurisdiction',
    key: 'localise_programme'
  },
  {
    label: 'Advice on change in law',
    key: 'change_advice'
  },
  {
    label: 'Data protection re-papering exercise',
    key: 're_papering'
  },
  {
    label: 'Direct marketing advice',
    key: 'marketing_advice'
  },
  {
    label: 'Record retention / destruction programme',
    key: 'record_retention'
  },
  {
    label: 'Data security breach',
    key: 'data_security_breach'
  },
  {
    label: 'Exercise of data subject rights',
    key: 'exercise_subject_rights'
  },
  {
    label: 'Data protection regulatory investigation / dispute',
    key: 'regulatory_investigation'
  },
  {
    label: 'Retainer for ad hoc data protection queries',
    key: 'protection_retainer'
  },
  {
    label: 'Data protection training',
    key: 'protection_training'
  },
  {
    label: 'Data protection privacy Other',
    key: 'other'
  },

  // Employmnet
  {
    label: 'Retainer for general HR legal advice and support',
    key: 'retainer_hr'
  },
  {
    label: 'Review of HR contracts, policies and handbooks',
    key: 'review_hr'
  },
  {
    label: 'Drafting/reviewing employment contracts, offer letters for incoming hire',
    key: 'review_contracts'
  },
  {
    label: 'Advising on restrictions relevant to potential new hires',
    key: 'hire_advice'
  },
  {
    label: 'Redundancy(ies)',
    key: 'redundancy'
  },
  {
    label: 'Dismissal(s)/ settlement agreement',
    key: 'dismissal'
  },
  {
    label: 'Employment benefits',
    key: 'employment_benefits'
  },
  {
    label: 'Consultants, contractors or agency worker arrangements',
    key: 'consultants_arrangements'
  },
  {
    label: 'Supply of staff arrangements',
    key: 'staff_supply'
  },
  {
    label: 'HR aspects of outsourcing and insourcing',
    key: 'hr_outsourcing'
  },
  {
    label: 'Advice in respect of a grievance',
    key: 'grievance_advice'
  },
  {
    label: 'Advice in respect of a disciplinary process',
    key: 'disciplinary_advice'
  },
  {
    label: 'Cross border advisory project',
    key: 'cross_border_advisory'
  },
  {
    label: 'Immigration and visas',
    key: 'immigration'
  },
  {
    label: 'Discrimination/ diversity/ equal opportunities',
    key: 'discrimination'
  },
  {
    label: 'Employment Investigation',
    key: 'employment_investigation'
  },
  {
    label: 'New/ proposed employment legislation',
    key: 'employment_legislation'
  },
  {
    label: 'Reporting obligations e.g. gender pay gap',
    key: 'reporting_obligations'
  },
  {
    label: 'Protecting confidential information',
    key: 'protecting_confidential_info'
  },
  {
    label: 'Performance management',
    key: 'performance_management'
  },
  {
    label: 'Long term sickness',
    key: 'long_term_sickness'
  },
  {
    label: 'Remuneration package and incentives design',
    key: 'package_design'
  },
  {
    label: 'Retainer for ongoing remuneration/incentives advice',
    key: 'ongoing_advice_retainer'
  },
  {
    label: 'Whistleblowing',
    key: 'whistleblowing'
  },
  {
    label: 'Employee health and safety',
    key: 'employee_health_safety'
  },
  {
    label: 'Advice related to pensions',
    key: 'pension_advice'
  },
  {
    label: 'Employment Other',
    key: 'other'
  },
  // Fund Investment
  {
    label: 'Summarising key terms of the Fund’s documents and providing a material issues list',
    key: 'summarising_key_terms'
  },
  {
    label:
      'Negotiating with the Fund’s legal counsel on the Fund’s documents (mainly limited partnership agreement or equivalent, side letter and subscription agreement);',
    key: 'negotiating_legal_counsel'
  },
  {
    label: 'Related tax advice',
    key: 'related_tax_advice'
  },
  {
    label: 'Assistance in execution and closing',
    key: 'assistance_execution_closing'
  },
  // IP
  {
    label: 'Portfolio management',
    key: 'portfolio_management'
  },
  {
    label: 'Commercialisation',
    key: 'commercialisation'
  },
  {
    label: 'Enforcement',
    key: 'enforcement'
  },
  {
    label: 'Other',
    key: 'other'
  },
  //IT
  {
    label: 'Privacy and cyber security advisory',
    key: 'category.privacy_cyber_security'
  },
  {
    label: 'Outsourcing/strategic commercial contracts (including cloud deployments)',
    key: 'category.outsourcing_commercial_contracts'
  },
  {
    label: 'Tech joint ventures/business collaborations',
    key: 'category.tech_joint_ventures'
  },
  {
    label: 'Licensing and general commercial',
    key: 'category.licensing_general_commercial'
  },
  {
    label: 'Fintech',
    key: 'category.fintech'
  },
  {
    label: 'It Other',
    key: 'category.other'
  },
  {
    label: 'Kick off – initial meeting, review of documentation, term sheet preparation/review and negotiation, review of vendors bids',
    key: 'phases.kick_off_meeting'
  },
  {
    label: 'Documentation preparation/review',
    key: 'phases.documentation_preparation'
  },
  {
    label: 'It Negotiation',
    key: 'phases.negotiation'
  },
  {
    label: 'Closing',
    key: 'phases.closing'
  },
  {
    label: 'Memo of advice',
    key: 'phases.memo_advice'
  },
  // Litigation
  {
    label: 'Investigation phase',
    key: 'investigation_phase'
  },
  {
    label: 'Pleadings',
    key: 'pleadings'
  },
  {
    label: 'Dispositive motion/motion to dismiss (US mainly)',
    key: 'dispositive_motion'
  },
  {
    label: 'Exchange of evidence',
    key: 'exchange_evidence'
  },
  {
    label: 'Pre trial',
    key: 'pre_trial'
  },
  {
    label: 'Trial',
    key: 'trial'
  },
  {
    label: 'Settlement',
    key: 'settlement'
  },
  {
    label: 'Appeal',
    key: 'appeal'
  },
  {
    label: 'Enforcement',
    key: 'enforcement'
  },
  {
    label: 'litigation Other',
    key: 'other'
  },
  {
    label: 'Comment',
    key: 'comment'
  },
  // Abstraction
  {
    label: 'Investigation phase',
    key: 'investigation_phase'
  },
  {
    label: 'Request for arbitration/answer/counterclaimse',
    key: 'request_for_arbitration'
  },
  {
    label: 'Initial memoranda',
    key: 'initial_memoranda'
  },
  {
    label: 'Exchange of evidence',
    key: 'exchange_evidence'
  },
  {
    label: 'Additional memoranda',
    key: 'additional_memoranda'
  },
  {
    label: 'Hearing',
    key: 'hearing'
  },
  {
    label: 'Post-hearing memoranda',
    key: 'post_hearing_memoranda'
  },
  {
    label: 'Settlement',
    key: 'settlement'
  },
  {
    label: 'Appeal',
    key: 'appeal'
  },
  {
    label: 'Enforcement/annulment',
    key: 'enforcement_annulment'
  },
  {
    label: 'Arbitration Other',
    key: 'other'
  },
  //regulatory
  {
    label: 'Role in Restructuring matter',
    key: 'role_in_restructuring'
  },
  {
    label: 'Rescue securitisations',
    key: 'rescue_securitisations'
  },
  {
    label: 'Moratorium/Rescheduling of debts',
    key: 'moratorium_debts'
  },
  {
    label: 'Debt to equity swaps',
    key: 'debt_equity_swaps'
  },
  {
    label: 'Corporate restructurings (eg. schemes of arrangements)',
    key: 'corporate_restructurings'
  },
  {
    label: 'Corporate finance transactions restructuring (e.g. buy-outs, M&A, rights issues)',
    key: 'corporate_finance_transactions'
  },
  {
    label: 'Structured receiverships',
    key: 'structured_receiverships'
  },
  {
    label: 'Insolvency processes (formal cram down processes, pre-pack administrations or bankruptcy sales and purchases)',
    key: 'insolvency_processes'
  },
  {
    label: 'Contingency planning',
    key: 'contingency_planning'
  },
  {
    label: 'Enforcing security',
    key: 'enforcing_security'
  },
  {
    label: 'Distressed debt trading',
    key: 'distressed_debt_trading'
  },
  {
    label: 'Other',
    key: 'other'
  },
  //Insolvency
  {
    label: 'Investigation phase',
    key: 'investigation_phase'
  },
  {
    label: 'Pleadings',
    key: 'pleadings'
  },
  {
    label: 'Dispositive motion/motion to dismiss (US mainly)',
    key: 'dispositive_motion'
  },
  {
    label: 'Exchange of evidence',
    key: 'exchange_evidence'
  },
  {
    label: 'Pre trial',
    key: 'pre_trial'
  },
  {
    label: 'Trial',
    key: 'trial'
  },
  {
    label: 'Settlement',
    key: 'settlement'
  },
  {
    label: 'Appeal',
    key: 'appeal'
  },
  {
    label: 'Enforcement',
    key: 'enforcement'
  },
  {
    label: 'Insolvency Other',
    key: 'other'
  },
  {
    label: 'Comment',
    key: 'comment'
  },
  // Infrastructure and project financing
  {
    label: 'Tender phase',
    key: 'tender_phase'
  },
  {
    label: 'Structuring, establishment of SPV vehicle',
    key: 'structuring'
  },
  {
    label: 'Due diligence (regulatory, licences, real estate)',
    key: 'due_diligence'
  },
  {
    label: 'Sponsor documents',
    key: 'sponsor_documents'
  },
  {
    label: 'Project Agreements',
    key: 'project_agreements'
  },
  {
    label: 'Financing',
    key: 'financing'
  },
  {
    label: 'Closing',
    key: 'closing'
  },
  //Other
  {
    label: 'Other',
    key: 'other'
  },
  //Competition
  {
    label: 'Merger control advice/filing',
    key: 'transactional_work.merger_control'
  },
  {
    label: 'Transactional Advice',
    key: 'transactional_work.advice'
  },
  {
    label: 'Cartel Investigation',
    key: 'behavioural_work.cartel_investigation'
  },
  {
    label: 'Antitrust Investigation',
    key: 'behavioural_work.antitrust_investigation'
  },
  {
    label: 'State Aid Investigation',
    key: 'behavioural_work.state_aid_investigation'
  },
  {
    label: 'Behavioral Advice',
    key: 'behavioural_work.advice'
  },
  {
    label: 'Other',
    key: 'other'
  },
  //Corporate MA

  {
    label: 'Term Sheet Negotiation',
    key: 'term_sheet_negotiation'
  },
  {
    label: 'Due Diligence',
    key: 'due_diligence'
  },
  {
    label: 'Red Flag Only',
    key: 'red_flag_only'
  },
  {
    label: 'Vendor ldd available',
    key: 'vendor_ldd_available'
  },
  {
    label: 'Online data room',
    key: 'online_data_room'
  },
  {
    label: 'Key area of focus',
    key: 'key_area_of_focus'
  },
  {
    label: 'Regulatory',
    key: 'regulatory'
  },
  {
    label: 'Transaction Documentation',
    key: 'transaction_documentation'
  },
  {
    label: 'Draft main documents (hold the pen)',
    key: 'draft_document'
  },
  {
    label: 'Review main documents',
    key: 'review_document'
  },
  {
    label: 'WI Policy',
    key: 'wi_policy'
  },
  {
    label: 'Financing',
    key: 'financing'
  },
  {
    label: 'Signing Process',
    key: 'signing_process'
  },
  {
    label: 'Closing Process',
    key: 'closing_process'
  },
  {
    label: 'Other',
    key: 'other'
  },
  //fund Formation
  {
    label: 'FundFormation Structuring',
    key: 'structuring'
  },
  {
    label: 'Term sheet',
    key: 'term_sheet'
  },
  {
    label: 'Comment',
    key: 'comment'
  },
  {
    label: 'Marketing',
    key: 'marketing'
  },
  {
    label: 'Reviewing pitch book/teaser',
    key: 'reviewing_pitch_book'
  },
  {
    label: 'Marketing advice/selling restrictions',
    key: 'marketing_advice'
  },
  {
    label: 'Fund interests into EU jurisdictions under AIFMD',
    key: 'fund_interests'
  },
  {
    label: 'All relevant non-U.S. and non-EU jurisdictions',
    key: 'non_us_eu_jurisdictions'
  },
  {
    label: 'Marketing in the US',
    key: 'marketing_in_us'
  },
  {
    label: `Advising on U.S. private placement exemptions, including providing a brief 'ground rules' memorandum containing practical advice on how to conduct the offering in compliance with Regulations D and S, including how to deal with media enquiries`,
    key: 'private_placement_exemptions'
  },
  {
    label: 'Providing a U.S. securities law opinion.',
    key: 'us_securities_law'
  },
  {
    label: `Completing U.S. Regulation D filings (if required) and, to the extent required, any applicable state 'blue sky' filings.`,
    key: 'us_regulation_filings'
  },
  {
    label: 'Drafting PPM legal aspects',
    key: 'drafting_ppm'
  },
  {
    label: 'Core documents',
    key: 'core_documents'
  },
  {
    label: 'Ancilliary documents',
    key: 'ancilliary_documents'
  },
  {
    label: 'Entity establishment',
    key: 'entity_establishment'
  },
  {
    label: 'Negotiations',
    key: 'negotiations'
  },
  {
    label: 'Closings',
    key: 'closings'
  },
  {
    label: 'Post Closing',
    key: 'post_closing'
  },
  {
    label: 'Optional Workstreams',
    key: 'optional_workstreams'
  },
  //Tax
  {
    label: 'Type',
    key: 'type'
  },
  // nestedFields: [
  {
    label: 'Transactional Tax',
    key: 'transactional_tax'
  },
  {
    label: 'Tax investigation & dispute',
    key: 'tax_investigation_dispute'
  },
  {
    label: 'Transfer pricing',
    key: 'transfer_pricing'
  },
  {
    label: 'Indirect tax',
    key: 'indirect_tax'
  },
  {
    label: 'Tax planning',
    key: 'tax_planning'
  },
  {
    label: 'Tax policy',
    key: 'tax_policy'
  },
  {
    label: 'Type Other',
    key: 'type.other'
  },
  // ]
  // },
  {
    label: 'Staged approach',
    key: 'staged_approach'
  },
  {
    label: 'Implementation',
    key: 'implementation'
  },
  // nestedFields: [
  {
    label: 'Corporate in charge of implementation',
    key: 'corporate_in_charge'
  },
  {
    label: 'Legal services provider to advise on implementation',
    key: 'legal_services_advise'
  },

  {
    key: 'non_exclusive_basis',
    label: 'We agree for legal services providers to work on a non exclusive basis with appropriate Chinese walls between each team'
  },
  {
    key: 'exclusive_basis_only',
    label: 'We request legal services providers to work on an exclusive basis only'
  },
  {
    key: 'asset_leasing_finance',
    label: 'Asset and leasing finance'
  },

  {
    key: 'corporate_lending',
    label: 'Corporate lending'
  },
  {
    key: 'debt_capital_markets',
    label: 'Debt capital markets'
  },
  {
    key: 'equity_capital_markets',
    label: 'Equity capital markets'
  },
  {
    key: 'islamic_finance',
    label: 'Islamic finance'
  },
  {
    key: 'leveraged_acquisition_finance',
    label: 'Leveraged and acquisition finance'
  },
  {
    key: 'real_estate_finance',
    label: 'Real estate finance'
  },
  {
    key: 'securitisation',
    label: 'Securitisation'
  },
  {
    key: 'derivatives_structured_products',
    label: 'Derivatives & structured products'
  },
  {
    key: 'structured_credit_products',
    label: 'Structured credit products'
  },
  {
    key: 'structured_equity_products',
    label: 'Structured equity products'
  },
  {
    key: 'interest_rate',
    label: 'Interest rate, FX and inflation products'
  },
  {
    key: 'commodity_linked_transactions',
    label: 'Commodity linked transactions'
  },
  {
    key: 'etp_platforms',
    label: 'ETP platforms'
  },
  {
    key: 'prime_brokerage',
    label: 'Prime brokerage and intermediation arrangements'
  },
  {
    key: 'credit_risk_mitigation',
    label: 'Credit risk mitigation'
  },
  {
    key: 'repos_securities_lending',
    label: 'Repos and securities lending'
  },
  {
    key: 'other',
    label: 'Derivatives & structured Other'
  },
  {
    key: 'trade_commodities_finance',
    label: 'Trade & commodities finance'
  },
  {
    key: 'corporate_trust',
    label: 'Corporate trust'
  },
  {
    key: 'other',
    label: 'Financing Other'
  },
  {
    key: 'regulatory_compliance_advocacy',
    label: 'Regulatory compliance/advocacy'
  },
  {
    key: 'enforcement',
    label: 'Enforcement'
  },
  {
    key: 'additional_information',
    label: 'Additional Information'
  },
  {
    key: 'structuring',
    label: 'Asset and leasing Structuring'
  },
  {
    key: 'documentation',
    label: 'Asset and leasing Documentation (list)'
  },
  {
    key: 'advisory',
    label: 'Asset and leasing Advisory/legal opinion/CP checklist, enforcement memorandum'
  },
  {
    key: 'due_diligence',
    label: 'Asset and leasing Due diligence (indebtedness)'
  }
];
