import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorHandler } from '../utils/errorHandler';
import { CorporateArrayInfo, InviteEmailInfo } from 'src/models/form_post';
import { LegalServicesArrayInfo } from 'src/models/form_post';
import { UploadLogoInfo } from 'src/models/upload_logo';
import { apiPost } from 'src/utils/axios';
import { postCuSignup, postUploadLogo } from 'src/utils/pathConst';
import { postLsSignup } from 'src/utils/pathConst';
import { postGaInvite } from 'src/utils/pathConst';

export type ErrorInformation = {
  message: string;
  status: number;
};

type PostState = {
  loading: boolean;
  error: ErrorInformation;
  corporateArray: CorporateArrayInfo;
  newObj: LegalServicesArrayInfo;
  inviteArray: InviteEmailInfo;
  uploadLogo: UploadLogoInfo;
};

const initialState: PostState = {
  loading: true,
  error: null,
  corporateArray: null,
  newObj: null,
  inviteArray: null,
  uploadLogo: null
};

export const PostCu = createAsyncThunk<
  any,
  { corporateArray: CorporateArrayInfo },
  {
    rejectValue: ErrorInformation;
  }
>('post/postCuUser', async ({ corporateArray }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postCuSignup}`, corporateArray);
    return response;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const PostLs = createAsyncThunk<
  LegalServicesArrayInfo,
  { newObj },
  {
    rejectValue: ErrorInformation;
  }
>('post/postLsUser', async ({ newObj }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postLsSignup}`, newObj);
    return response.data[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const PostGa = createAsyncThunk<
  InviteEmailInfo,
  { inviteArray: InviteEmailInfo },
  {
    rejectValue: ErrorInformation;
  }
>('post/postGaInvite', async ({ inviteArray }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postGaInvite}`, inviteArray);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

// const postSlice = createSlice({
export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {},
  extraReducers: {
    //get user information
    [PostCu.pending.type]: (state) => {
      state.loading = true;
    },
    [PostCu.fulfilled.type]: (state, action: PayloadAction<CorporateArrayInfo | null>) => {
      state.corporateArray = action.payload;
      state.loading = false;
    },
    [PostCu.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // PST for LS
    [PostLs.pending.type]: (state) => {
      state.loading = true;
    },
    [PostLs.fulfilled.type]: (state, action: PayloadAction<LegalServicesArrayInfo | null>) => {
      state.newObj = action.payload;
      state.loading = false;
    },
    [PostLs.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    //PostGa invite slice
    [PostGa.pending.type]: (state) => {
      state.loading = true;
    },
    [PostGa.fulfilled.type]: (state, action: PayloadAction<InviteEmailInfo | null>) => {
      state.inviteArray = action.payload;
      state.loading = false;
    },
    [PostGa.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export default postSlice;

export const uploadLogofile = createAsyncThunk<
  UploadLogoInfo,
  { uploadLogo },
  {
    rejectValue: ErrorInformation;
  }
>('post/postUploadLogo', async ({ uploadLogo }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postUploadLogo}`, uploadLogo);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postUploadLogoSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {},
  extraReducers: {
    //get user information
    [uploadLogofile.pending.type]: (state) => {
      state.loading = true;
    },
    [uploadLogofile.fulfilled.type]: (state, action: PayloadAction<UploadLogoInfo | null>) => {
      state.uploadLogo = action.payload;
      state.loading = false;
    },
    [uploadLogofile.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});
