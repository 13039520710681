import { lazy, Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Callback from 'src/content/pages/Callback';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Dashboard = Loader(lazy(() => import('src/content/cuModule/dashboard')));
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));

//Rfp
const ConflictCheck = Loader(lazy(() => import('src/content/cuModule/Rfp/index')));
const RfpForm = Loader(lazy(() => import('src/content/cuModule/Rfp/index')));
const RfpList = Loader(lazy(() => import('src/content/cuModule/ListRfp/rfpList')));
const Analytics = Loader(lazy(() => import('src/content/cuModule/Analytics/Analytics')));
const Users = Loader(lazy(() => import('src/content/cuModule/Users/index')));
const AddReview = Loader(lazy(() => import('src/content/cuModule/Review/addReview')));
const ListReview = Loader(lazy(() => import('src/content/cuModule/Review/listReview')));
// const Preview = Loader(lazy(() => import('src/content/cuModule/Review/---previewReview')));
// const Payments = Loader(lazy(() => import('src/content/cuModule/paymentspage')));
const ProposalWinner = Loader(lazy(() => import('src/content/cuModule/ListRfp/ProposalWinner')));
const ProposalSelection = Loader(lazy(() => import('src/content/cuModule/ListRfp/ProposalSelection')));
const ProposalPreview = Loader(lazy(() => import('src/content/cuModule/ListRfp/Preview/index')));
const TemplateCreate = Loader(lazy(() => import('src/content/cuModule/TemplateBank/Index')));
const TemplateList = Loader(lazy(() => import('src/content/cuModule/TemplateBank/listTemplate')));
const SelectTemplateList = Loader(lazy(() => import('src/content/cuModule/TemplateBank/SelectTemplateList')));
const PastRfpList = Loader(lazy(() => import('src/content/cuModule/PastRfp/PastRfpList')));
const PastRfpCreate = Loader(lazy(() => import('src/content/cuModule/PastRfp/Index')));
const AccountPreview = Loader(lazy(() => import('src/content/cuModule/MyAccount/AccountDetails')));
const FAQ = Loader(lazy(() => import('src/content/cuModule/FAQ/FAQ')));
const HelpCenter = Loader(lazy(() => import('src/content/cuModule/FAQ/HelpCenter')));
const FAQCenter = Loader(lazy(() => import('src/content/cuModule/FAQ/FaqCenter')));
const FavoriteBidders = Loader(lazy(() => import('src/content/cuModule/FavoriteBidders/index')));
const UserGroup = Loader(lazy(() => import('src/content/cuModule/UserGroup/index')));
const AddUserGroup = Loader(lazy(() => import('src/content/cuModule/UserGroup/Create')));
const LspGroup = Loader(lazy(() => import('src/content/cuModule/UserGroup/CreateMember')));

export const cuDashboardsRoutes = [
  {
    path: '',
    element: <Dashboard />
  },
  {
    path: 'corporate/dashboard',
    element: <Dashboard />
  },
  {
    path: 'corporate/analytics',
    element: <Analytics />
  },
  {
    path: 'corporate/rfp-list',
    element: <RfpList />
  },
  {
    path: 'corporate/users',
    element: <Users />
  },
  {
    path: 'corporate/faq',
    element: <FAQ />
  },
  {
    path: 'corporate/help-center',
    element: <HelpCenter />
  },
  {
    path: 'corporate/faq-center',
    element: <FAQCenter />
  },
  // {
  //   path: 'corporate/legalservice-review',
  //   element: <LegalServiceReview />
  // },
  // {
  //   path: 'corporate/legalservicecompanyreview',
  //   element: <LegalServiceCompanyReview />
  // },
  {
    path: 'corporate/preferred-law-firms',
    element: <FavoriteBidders />
  },

  {
    path: 'corporate/add-review',
    element: <AddReview />
  },
  {
    path: 'corporate/list-review',
    element: <ListReview />
  },
  // {
  //   path: 'corporate/preview-review',
  //   element: <Preview />
  // },
  {
    path: 'corporate/template-bank/list',
    element: <TemplateList />
  },
  {
    path: 'corporate/template-bank/select-list',
    element: <SelectTemplateList />
  },
  {
    path: 'corporate/past-rfp/list',
    element: <PastRfpList />
  },
  // {
  //   path: 'corporate/comparison',
  //   element: <ProposalWinner />
  // },
  // {
  //   path: 'corporate/proposal-selection',
  //   element: <ProposalSelection />
  // },
  {
    path: 'corporate/proposal-preview',
    element: <ProposalPreview />
  },
  {
    path: 'corporate/account-preview',
    element: <AccountPreview />
  },
  // {
  //   path: 'corporate/payments',
  //   element: <Payments />
  // },
  {
    path: 'corporate/user-group',
    element: <UserGroup />
  },
  {
    path: 'corporate/user-group/add-user-group',
    element: <AddUserGroup />
  },
  {
    path: 'corporate/user-group/add-user-group/corporate-provider-group',
    element: <LspGroup />
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export const RFPSelectionRoutes = [
  {
    path: 'corporate/comparison',
    element: <ProposalWinner />
  },
  {
    path: 'corporate/proposal-selection',
    element: <ProposalSelection />
  }
];

export const RFPRoutes = [
  {
    path: 'create',
    element: <ConflictCheck />
  },
  {
    path: 'create/conflict',
    element: <RfpForm />
  },
  {
    path: 'create/description',
    element: <RfpForm />
  },
  {
    path: 'create/scope',
    element: <RfpForm />
  },
  {
    path: 'create/pricing',
    element: <RfpForm />
  },
  {
    path: 'create/other',
    element: <RfpForm />
  },
  {
    path: 'create/bidders',
    element: <RfpForm />
  },
  {
    path: 'create/disclose-bidders',
    element: <RfpForm />
  },
  {
    path: 'create/preview',
    element: <RfpForm />
  },
  {
    path: 'create/submit',
    element: <RfpForm />
  },
  {
    path: 'create/bidders',
    element: <Callback />
  }
];

export const RFPCuRoutes = [
  {
    path: 'rfp',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/conflict',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/description',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/scope',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/pricing',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/other',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/bidders',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/disclose-bidders',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/preview',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/submit',
    element: <TemplateCreate />
  },
  {
    path: 'rfp/priliminaryinfo',
    element: <TemplateCreate />
  }
];

export const PastRFP = [
  {
    path: 'proposal',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/conflict',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/description',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/scope',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/pricing',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/other',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/bidders',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/disclose-bidders',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/preview',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/submit',
    element: <PastRfpCreate />
  },
  {
    path: 'proposal/priliminaryinfo',
    element: <PastRfpCreate />
  }
];
