import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoggedUser } from 'src/models/loggedUser';
import { errorHandler } from '../utils/errorHandler';
import { apiDelete, apiGet, apiPost } from 'src/utils/axios';
import { deleteAllSessionUrl, deleteSessionUrl, getUserDetailUrl, getUserURL, createSessionUrl } from 'src/utils/pathConst';

export type ErrorInformation = {
  message: string;
  status: number;
};

type UserState = {
  loading: boolean;
  error: ErrorInformation;
  users: LoggedUser;
  usersList: any;
};

const initialState: UserState = {
  loading: true,
  error: null,
  users: null,
  usersList: null
};

export const getUser = createAsyncThunk<
  LoggedUser,
  { userId? },
  {
    rejectValue: ErrorInformation;
  }
>('user/getUser', async ({ userId }, { rejectWithValue }) => {
  try {
    const id = userId ? userId : localStorage.getItem('loggedUserId');
    const response = await apiGet(`${getUserURL + id}`);
    localStorage.setItem('userDetail', JSON.stringify(response?.data?.detail));
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const createSession = createAsyncThunk<
  any,
  { sessionIds: any },
  {
    rejectValue: ErrorInformation;
  }
>('user/createSession', async ({ sessionIds }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${createSessionUrl}`, sessionIds);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const deleteSession = createAsyncThunk<
  any,
  { sessionIds: any },
  {
    rejectValue: ErrorInformation;
  }
>('user/deleteSession', async ({ sessionIds }, { rejectWithValue }) => {
  try {
    const response = await apiDelete(`${deleteSessionUrl}`, sessionIds);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getUserDetail = createAsyncThunk<
  LoggedUser,
  { userId? },
  {
    rejectValue: ErrorInformation;
  }
>('user/createSessionUrl', async ({ userId }, { rejectWithValue }) => {
  try {
    const response = await apiGet(`${getUserDetailUrl + userId}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const deleteAllSession = createAsyncThunk<
  any,
  { body: any },
  {
    rejectValue: ErrorInformation;
  }
>('user/createSession', async ({ body }, { rejectWithValue }) => {
  try {
    const response = await apiDelete(`${deleteAllSessionUrl}`, body);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    //get user information
    [getUser.pending.type]: (state) => {
      state.loading = true;
    },
    [getUser.fulfilled.type]: (state, action: PayloadAction<LoggedUser | null>) => {
      state.users = action.payload;
      state.loading = false;
    },
    [getUser.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getUserDetail.pending.type]: (state) => {
      state.loading = true;
    },
    [getUserDetail.fulfilled.type]: (state, action: PayloadAction<LoggedUser | null>) => {
      state.usersList = action.payload;
      state.loading = false;
    },
    [getUserDetail.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export default userSlice;
