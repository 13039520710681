import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Authenticated from 'src/components/Authenticated';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import gaDashboardsRoutes from './paths';
import Callback from 'src/content/pages/Callback';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const RFPs = Loader(lazy(() => import('src/content/gaModule/RfpList')));

const router2: RouteObject[] = [
  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        children: gaDashboardsRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: 'admin/all-rfp-list',
        element: <RFPs />
      }
    ]
  },
  {
    path: 'callback',
    element: <Callback />
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default router2;
