import { RouteObject } from 'react-router';
import Authenticated from 'src/components/Authenticated';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import { cuDashboardsRoutes, RFPRoutes, PastRFP, RFPSelectionRoutes, RFPCuRoutes } from './paths';
import Callback from 'src/content/pages/Callback';

const cuRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        children: cuDashboardsRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        children: RFPSelectionRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: 'corporate/rfp',
        children: RFPRoutes
      },
      {
        path: 'corporate/past-rfp',
        children: PastRFP
      },
      {
        path: 'corporate/template-bank',
        children: RFPCuRoutes
      }
    ]
  },
  {
    path: 'callback',
    element: <Callback />
  }
];

export default cuRoutes;
