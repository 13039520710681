import { Box } from '@mui/material';
// import Logo from 'src/components/Logo';

function AppInit() {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {/* <Logo /> */}
      <img src="/static/images/dots-loader.gif" alt="loader" />
    </Box>
  );
}

export default AppInit;
