import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorHandler } from '../utils/errorHandler';
import { apiGet, apiPost } from 'src/utils/axios';
import {
  IndustryInfo,
  HowYouHereAboutUsModel,
  CompanyNameInfo,
  DepartmentNameInfo,
  CorporateSpendInfo,
  CountryInfo,
  JurisdictionInfo,
  LanguageInfo,
  TimeZonesInfo,
  KeyAreasOfFocusInfo,
  RoleInRestructuringInfo,
  ScopeOfWorksInfo,
  CurrencyInfo,
  CompletionTimeWeeksInfo,
  SelectCorporate,
  SelectLegalService,
  LegalInfo,
  LegalTableInfo,
  LSQueryParams,
  CRQueryParams,
  CorporaterTableInfo,
  CorporateStatus,
  CorporatePreviewModel,
  Corporateparams,
  LegalServicePreviewSchema,
  LegalServiceparams,
  CorporateApproveDeclineModel,
  CorporateApproveDeclineParams,
  CorporateRequestForMissingInfo,
  CorporateRequestForMissingInfoModel,
  ContactUsModel,
  RetryPaymentModel,
  CorporateRfpSpendInfo
} from 'src/models/select';
import {
  getIndustryList,
  getHowYouHereAboutUs,
  getCompanyNameList,
  getDepartmentNameList,
  getCorporateSpendList,
  getCountryList,
  getJurisdictionList,
  getLanguageList,
  getTimeZonesList,
  getKeyAreasOfFocusList,
  getRestructuringList,
  getScopeOfWorkList,
  getCurrencyList,
  getCompletionTimeWeeksList,
  getCorporateSelectList,
  getLegalserviceslist,
  getLegalServiceSelectList,
  getCorporateList,
  getCorporatePreview,
  getLegalServicePreview,
  postCorporateApproveDecline,
  postRequestForMissingInfo,
  postContactDetail,
  getFinancial,
  retryPaymentUrl,
  getCorporateRfpSpendList
} from 'src/utils/pathConst';

export type ErrorInformation = {
  message: string;
  status: number;
};

type SelectState = {
  loading: boolean;
  error: ErrorInformation;
  industryData: IndustryInfo;
  howYouHereAboutUsData: HowYouHereAboutUsModel;
  companyNameData: CompanyNameInfo;
  departmentNameData: DepartmentNameInfo;
  corporateSpendData: CorporateSpendInfo;
  corporateRfpSpendData: CorporateRfpSpendInfo;
  countryInfo: CountryInfo;
  jurisdictionInfo: JurisdictionInfo;
  languageInfo: LanguageInfo;
  timeZonesInfo: TimeZonesInfo;
  keyAreasOfFocusInfo: KeyAreasOfFocusInfo;
  restructuringInfo: RoleInRestructuringInfo;
  scopeOfWorksInfo: ScopeOfWorksInfo;
  currencyInfo: CurrencyInfo;
  completionTimeWeeksInfo: CompletionTimeWeeksInfo;
  selectCorporate: SelectCorporate;
  selectLegalService: SelectLegalService[];
  legalStatusInfo: LegalInfo;
  corporateStatus: CorporateStatus;
  legalTableInfo: LegalTableInfo;
  lSQueryParams: LSQueryParams;
  params: CRQueryParams;
  corporaterTableInfo: CorporaterTableInfo;
  financialTableInfo: any;
  corporatePreviewModel: CorporatePreviewModel;
  legalServicePreviewSchema: LegalServicePreviewSchema;
  corporateAprovalDeclineResponse: CorporateApproveDeclineModel;
  CorporateRequestForMissingInfoResponse: CorporateRequestForMissingInfoModel;
  ContactDetails: ContactUsModel;
};
const initialState: SelectState = {
  loading: true,
  error: null,
  industryData: null,
  companyNameData: null,
  departmentNameData: null,
  corporateSpendData: null,
  corporateRfpSpendData: null,
  countryInfo: null,
  jurisdictionInfo: null,
  languageInfo: null,
  timeZonesInfo: null,
  keyAreasOfFocusInfo: null,
  restructuringInfo: null,
  scopeOfWorksInfo: null,
  currencyInfo: null,
  completionTimeWeeksInfo: null,
  selectCorporate: null,
  legalStatusInfo: null,
  legalTableInfo: null,
  lSQueryParams: null,
  corporaterTableInfo: null,
  financialTableInfo: null,
  corporateStatus: null,
  howYouHereAboutUsData: null,
  params: null,
  selectLegalService: [],
  corporatePreviewModel: null,
  legalServicePreviewSchema: null,
  corporateAprovalDeclineResponse: null,
  CorporateRequestForMissingInfoResponse: null,
  ContactDetails: null
};

export const getIndustry = createAsyncThunk<
  IndustryInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getIndustry', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getIndustryList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const getHowYouHere = createAsyncThunk<
  HowYouHereAboutUsModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getHowYouHere', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getHowYouHereAboutUs}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCompanyName = createAsyncThunk<
  CompanyNameInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCompanyName', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCompanyNameList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getDepartmentName = createAsyncThunk<
  DepartmentNameInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getDepartmentName', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getDepartmentNameList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCorporateSpend = createAsyncThunk<
  CorporateSpendInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCorporateSpend', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCorporateSpendList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCorporateRfpSpend = createAsyncThunk<
  CorporateSpendInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCorporateRfpSpend', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCorporateRfpSpendList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCountry = createAsyncThunk<
  CorporateSpendInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCountry', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCountryList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getJurisdiction = createAsyncThunk<
  JurisdictionInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getJurisdiction', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getJurisdictionList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLanguage = createAsyncThunk<
  LanguageInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLanguage', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getLanguageList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getScopeOfWork = createAsyncThunk<
  ScopeOfWorksInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getScopeOfWork', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getScopeOfWorkList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCurrency = createAsyncThunk<
  CurrencyInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCurrency', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCurrencyList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCompletionTimeWeeks = createAsyncThunk<
  CompletionTimeWeeksInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCompletionTimeWeeks', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCompletionTimeWeeksList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getTimeZones = createAsyncThunk<
  TimeZonesInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getTimeZones', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getTimeZonesList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getKeyAreasOfFocus = createAsyncThunk<
  KeyAreasOfFocusInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getKeyAreasOfFocus', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getKeyAreasOfFocusList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getRestructuring = createAsyncThunk<
  RoleInRestructuringInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getRestructuring', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getRestructuringList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLegalService = createAsyncThunk<
  LegalTableInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLegalService', async (lSQueryParams: LSQueryParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getLegalserviceslist}?search_string=${lSQueryParams.search_string}&status=${lSQueryParams.status}&sortBy=${lSQueryParams.sortBy}&sortDir=${lSQueryParams.sortDir}&limit=${lSQueryParams.limit}&offset=${lSQueryParams.offset}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLegalServiceSelect = createAsyncThunk<
  SelectLegalService,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/selectLegalService', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getLegalServiceSelectList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//get Corporate Select
export const getCorporateSelect = createAsyncThunk<
  CorporateStatus,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/corporateStatus', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCorporateSelectList}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCorporates = createAsyncThunk<
  CorporaterTableInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/corporateTableInfo', async (params: CRQueryParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getCorporateList}?search_string=${params.search_string}&status=${params.status}&sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const getFinancialList = createAsyncThunk<
  CorporaterTableInfo,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/corporaterTableInfo', async (params: CRQueryParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getFinancial}?search_string=${params.search_string}&status=${params.status}&sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCorporatesPreviewDetail = createAsyncThunk<
  CorporatePreviewModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/corporatePreviewModel', async (params: Corporateparams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCorporatePreview}/${params.id}`);
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLegalservicePreviewDetail = createAsyncThunk<
  LegalServicePreviewSchema,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/legalServicePreviewSchema', async (params: LegalServiceparams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getLegalServicePreview}/${params.id}`);
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const corporateApproveDecline = createAsyncThunk<
  CorporateApproveDeclineModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/CorporateApproveDeclineModel', async (params: CorporateApproveDeclineParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postCorporateApproveDecline}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const retryPayment = createAsyncThunk<
  RetryPaymentModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/retryPayment', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${retryPaymentUrl}/${params.id}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const corporateSendMessage = createAsyncThunk<
  CorporateRequestForMissingInfoModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/CorporateRequestForMissingInfoModel', async (params: CorporateRequestForMissingInfo, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postRequestForMissingInfo}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const PostContactUsData = createAsyncThunk<
  ContactUsModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/ContactUsModel', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postContactDetail}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

const selectSlice = createSlice({
  name: 'select',
  initialState,
  reducers: {},
  extraReducers: {
    //get Industry information
    [getIndustry.pending.type]: (state) => {
      state.loading = true;
    },
    [getIndustry.fulfilled.type]: (state, action: PayloadAction<IndustryInfo | null>) => {
      state.industryData = action.payload;
      state.loading = false;
    },
    [getIndustry.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get How you here about us
    [getHowYouHere.pending.type]: (state) => {
      state.loading = true;
    },
    [getHowYouHere.fulfilled.type]: (state, action: PayloadAction<IndustryInfo | null>) => {
      state.howYouHereAboutUsData = action.payload;
      state.loading = false;
    },
    [getHowYouHere.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get companyInfo information
    [getCompanyName.pending.type]: (state) => {
      state.loading = true;
    },
    [getCompanyName.fulfilled.type]: (state, action: PayloadAction<CompanyNameInfo | null>) => {
      state.companyNameData = action.payload;
      state.loading = false;
    },
    [getCompanyName.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get corporate Spend information
    [getDepartmentName.pending.type]: (state) => {
      state.loading = true;
    },
    [getDepartmentName.fulfilled.type]: (state, action: PayloadAction<DepartmentNameInfo | null>) => {
      state.departmentNameData = action.payload;
      state.loading = false;
    },
    [getDepartmentName.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get corporate Spend information
    [getCorporateSpend.pending.type]: (state) => {
      state.loading = true;
    },
    [getCorporateSpend.fulfilled.type]: (state, action: PayloadAction<CorporateSpendInfo | null>) => {
      state.corporateSpendData = action.payload;
      state.loading = false;
    },
    [getCorporateSpend.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get corporate rfp enumSpend information
    [getCorporateRfpSpend.pending.type]: (state) => {
      state.loading = true;
    },
    [getCorporateRfpSpend.fulfilled.type]: (state, action: PayloadAction<CorporateSpendInfo | null>) => {
      state.corporateRfpSpendData = action.payload;
      state.loading = false;
    },
    [getCorporateRfpSpend.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get country information
    [getCountry.pending.type]: (state) => {
      state.loading = true;
    },
    [getCountry.fulfilled.type]: (state, action: PayloadAction<CountryInfo | null>) => {
      state.countryInfo = action.payload;
      state.loading = false;
    },
    [getCountry.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get Jurisdiction information
    [getJurisdiction.pending.type]: (state) => {
      state.loading = true;
    },
    [getJurisdiction.fulfilled.type]: (state, action: PayloadAction<JurisdictionInfo | null>) => {
      state.jurisdictionInfo = action.payload;
      state.loading = false;
    },
    [getJurisdiction.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get language information
    [getLanguage.pending.type]: (state) => {
      state.loading = true;
    },
    [getLanguage.fulfilled.type]: (state, action: PayloadAction<LanguageInfo | null>) => {
      state.languageInfo = action.payload;
      state.loading = false;
    },
    [getLanguage.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get Time Zones information
    [getTimeZones.pending.type]: (state) => {
      state.loading = true;
    },
    [getTimeZones.fulfilled.type]: (state, action: PayloadAction<TimeZonesInfo | null>) => {
      state.timeZonesInfo = action.payload;
      state.loading = false;
    },
    [getTimeZones.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get Key Area Of Focus information
    [getKeyAreasOfFocus.pending.type]: (state) => {
      state.loading = true;
    },
    [getKeyAreasOfFocus.fulfilled.type]: (state, action: PayloadAction<KeyAreasOfFocusInfo | null>) => {
      state.keyAreasOfFocusInfo = action.payload;
      state.loading = false;
    },
    [getKeyAreasOfFocus.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //get Restructuring information
    [getRestructuring.pending.type]: (state) => {
      state.loading = true;
    },
    [getRestructuring.fulfilled.type]: (state, action: PayloadAction<RoleInRestructuringInfo | null>) => {
      state.restructuringInfo = action.payload;
      state.loading = false;
    },
    [getRestructuring.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //getScopeOfWork information
    [getScopeOfWork.pending.type]: (state) => {
      state.loading = true;
    },
    [getScopeOfWork.fulfilled.type]: (state, action: PayloadAction<ScopeOfWorksInfo | null>) => {
      state.scopeOfWorksInfo = action.payload;
      state.loading = false;
    },
    [getScopeOfWork.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // currency information
    [getCurrency.pending.type]: (state) => {
      state.loading = true;
    },
    [getCurrency.fulfilled.type]: (state, action: PayloadAction<CurrencyInfo | null>) => {
      state.currencyInfo = action.payload;
      state.loading = false;
    },
    [getCurrency.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //completion weeks information
    [getCompletionTimeWeeks.pending.type]: (state) => {
      state.loading = true;
    },
    [getCompletionTimeWeeks.fulfilled.type]: (state, action: PayloadAction<CompletionTimeWeeksInfo | null>) => {
      state.completionTimeWeeksInfo = action.payload;
      state.loading = false;
    },
    [getCompletionTimeWeeks.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getLegalService.pending.type]: (state) => {
      state.loading = true;
    },
    [getLegalService.fulfilled.type]: (state, action: PayloadAction<LegalTableInfo | null>) => {
      state.legalTableInfo = action.payload;
      state.loading = false;
    },
    [getLegalService.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //legalServices select
    [getLegalServiceSelect.pending.type]: (state) => {
      state.loading = true;
    },
    [getLegalServiceSelect.fulfilled.type]: (state, action: PayloadAction<LegalInfo>) => {
      state.legalStatusInfo = action.payload;
      state.loading = false;
    },
    [getLegalServiceSelect.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //Corporate Select
    [getCorporateSelect.pending.type]: (state) => {
      state.loading = true;
    },
    [getCorporateSelect.fulfilled.type]: (state, action: PayloadAction<CorporateStatus | null>) => {
      state.corporateStatus = action.payload;
      state.loading = false;
    },
    [getCorporateSelect.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //Corporate Table
    [getCorporates.pending.type]: (state) => {
      state.loading = true;
    },
    [getCorporates.fulfilled.type]: (state, action: PayloadAction<CorporaterTableInfo | null>) => {
      state.corporaterTableInfo = action.payload;
      state.loading = false;
    },
    [getCorporates.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //Financial Table
    [getFinancialList.pending.type]: (state) => {
      state.loading = true;
    },
    [getFinancialList.fulfilled.type]: (state, action: PayloadAction<CorporaterTableInfo | null>) => {
      state.financialTableInfo = action.payload;
      state.loading = false;
    },
    [getFinancialList.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //Corporate Preview
    [getCorporatesPreviewDetail.pending.type]: (state) => {
      state.loading = true;
    },
    [getCorporatesPreviewDetail.fulfilled.type]: (state, action: PayloadAction<CorporatePreviewModel | null>) => {
      state.corporatePreviewModel = action.payload;
      state.loading = false;
    },
    [getCorporatesPreviewDetail.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //LegalServices preview
    [getLegalservicePreviewDetail.pending.type]: (state) => {
      state.loading = true;
    },
    [getLegalservicePreviewDetail.fulfilled.type]: (state, action: PayloadAction<LegalServicePreviewSchema | null>) => {
      state.legalServicePreviewSchema = action.payload;
      state.loading = false;
    },
    [getLegalservicePreviewDetail.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Corporate approval
    [corporateApproveDecline.pending.type]: (state) => {
      state.loading = true;
    },
    [corporateApproveDecline.fulfilled.type]: (state, action: PayloadAction<CorporateApproveDeclineModel | null>) => {
      state.corporateAprovalDeclineResponse = action.payload;
      state.loading = false;
    },
    [corporateApproveDecline.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Corporate send message
    [corporateSendMessage.pending.type]: (state) => {
      state.loading = true;
    },
    [corporateSendMessage.fulfilled.type]: (state, action: PayloadAction<CorporateRequestForMissingInfoModel | null>) => {
      state.CorporateRequestForMissingInfoResponse = action.payload;
      state.loading = false;
    },
    [corporateSendMessage.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [PostContactUsData.pending.type]: (state) => {
      state.loading = true;
    },
    [PostContactUsData.fulfilled.type]: (state, action: PayloadAction<ContactUsModel | null>) => {
      state.ContactDetails = action.payload;
      state.loading = false;
    },
    [PostContactUsData.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});
export default selectSlice;
