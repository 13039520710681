/* eslint-disable react/forbid-prop-types */
// AppContext.js
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

// Create the context
const AppContext = createContext(null);
export function AppProvider({ children }) {
  const [stateValue, setStateValue] = useState({
    isLoading: false,
    loadWithText: ''
  });

  const handleContextChange = (name, value, text = '') => {
    const newStateValue = { ...stateValue };
    newStateValue[name] = value;
    newStateValue.loadWithText = text;
    setStateValue(newStateValue);
  };

  // Pass the state and update function as values to the context provider
  return <AppContext.Provider value={{ stateValue, handleContextChange }}>{children}</AppContext.Provider>;
}

AppProvider.propTypes = {
  children: PropTypes.any
};

// Create a custom hook to consume the context
export function useAppContext() {
  return React.useContext(AppContext);
}
