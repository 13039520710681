import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { isValidJwtFormat } from './CommonUtils';

//==================|| AXIOS CONFIGURATION ||============//

export interface DecodedData {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  sub: string;
  auth_time: number;
  sid: string;
  nonce: string;
}

export function isTokenValid(token) {
  if (!token) {
    return false;
  }
  try {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken.exp && decodedToken.exp * 1000 > Date.now()) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

// function isJWT(String, jwt) {
//   const jwtSplitted = jwt.split("\\.");
//   if (jwtSplitted.length != 3) // The JWT is composed of three parts
//       return false;
//   try {
//       const jsonFirstPart = new String(Base64.getDecoder().decode(jwtSplitted[0]));
//       JSONObject firstPart = new JSONObject(jsonFirstPart); // The first part of the JWT is a JSON
//       if (!firstPart.has("alg")) // The first part has the attribute "alg"
//           return false;
//       String jsonSecondPart = new String(Base64.getDecoder().decode(jwtSplitted[1]));
//       JSONObject secondPart = new JSONObject(jsonSecondPart); // The first part of the JWT is a JSON
//       //Put the validations you think are necessary for the data the JWT should take to validate
//   }catch (JSONException err){
//       return false;
//   }
//   return true;
// }

// export const getAccessToken = async () => {
//   let token = localStorage.getItem('idToken');
//   if (!token) {
//     return false;
//   }
//   try {
//     const decodedToken: any = jwtDecode(token);
//     if (token && decodedToken.exp && decodedToken.exp * 1000 > Date.now()) {
//       return token;
//     }
//     return false;
//   } catch (error) {
//     return false;
//   }
// };

function removeAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export const getAccessToken = async () => {
  let token = localStorage.getItem('idToken');
  if (token) {
    if (!isValidJwtFormat(token)) {
      localStorage.clear();
      removeAllCookies();
      // eslint-disable-next-line no-undef
      window.location.assign(process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI);
    }
    const decodedToken: any = jwtDecode(token);
    if (decodedToken?.exp && decodedToken?.exp * 1000 > Date.now()) {
      return token;
    } else {
      localStorage.clear();
      removeAllCookies();
      // eslint-disable-next-line no-undef
      window.location.assign(process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI);
    }
    // return token;
  } else {
    return null;
  }
};

axios.interceptors.response.use(null, (error) => {
  if (!error.response.config.isNormal) {
    if (error && error.response) {
      if (error.response.status === 401) {
        // initial call to whoami will get intercepted here and redirect, need to throw log event
        // TODO: Do something
      }
      const { data } = error.response;
      if (data.message === 'Role is unauthorized to access resource') {
        window.location.assign(process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI);
      }
      // || data.error === 'Unauthorized'
      if (data.statusCode === 401) {
        alert('UnauthorizedError');
        localStorage.clear();
        removeAllCookies();
        // eslint-disable-next-line no-undef
        window.location.assign(process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI);
      }
      if (data.message === 'UnauthorizedError' && data.statusCode === 401) {
        localStorage.clear();
        removeAllCookies();
        // eslint-disable-next-line no-undef
        window.location.assign(process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI);
      }
      const errorPayload = data.errors || data.error_message || data.error_type || data.message || data;

      return Promise.reject(errorPayload);
    }

    return Promise.reject(error);
  } else {
    return Promise.reject(error.response.data);
  }
});
/**
 *
 * @param {*} path  endpoint
 * @returns data from api
 */
export async function apiGet(path) {
  const authToken = await getAccessToken();
  const config = {
    headers: {
      authorization: authToken ? `Bearer ${authToken}` : null
    }
  };
  return axios.get(path, config);
}

/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export async function apiPost(path, data, isNormal = false) {
  const authToken = await getAccessToken();
  const config = {
    headers: {
      authorization: authToken ? `Bearer ${authToken}` : null
      // 'Content-Type': 'application/json'
    },
    isNormal: isNormal
  };

  return axios.post(path, data, config);
}

/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */

export async function apiPatch(path, data, headers = {}) {
  const authToken = await getAccessToken();
  const config = {
    headers: {
      authorization: `Bearer ${authToken}`,
      ...headers
    }
  };

  return axios.patch(path, data, config);
}
/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export async function apiPut(path, data, isNormal = false) {
  const authToken = await getAccessToken();
  const config = {
    headers: {
      authorization: `Bearer ${authToken}`
    },
    isNormal: isNormal
  };

  return axios.put(path, data, config);
}
/**
 *
 * @param {*} path   endpoint
 * @param {*} data params
 * @returns   data from api
 */
export async function apiDelete(path, data) {
  const authToken = await getAccessToken();
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${authToken}`
  //   }
  // };
  const headers = {
    authorization: `Bearer ${authToken}`
  };

  // return axios.delete(path, {
  //   ...data,
  //   ...config
  // });
  return axios.delete(path, { data: data, headers: headers });
}

/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export function apiPostWithCustomToken(path, data, token) {
  const config = {
    headers: {
      authorization: token ? `Basic ${token}` : null,
      'Content-Type': 'application/json'
    }
  };

  return axios.post(path, data, config);
}

const instance = axios.create();

export default instance;
