import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import type { ReactNode } from 'react';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GavelIcon from '@mui/icons-material/Gavel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HelpIcon from '@mui/icons-material/Help';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BookmarkAddSharpIcon from '@mui/icons-material/BookmarkAddSharp';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
  tooltip?: string;
}
export interface GaItems {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
}
export interface GaMenuItems {
  items: MenuItem[];
}

export const menuItems: MenuItems[] = [
  {
    items: [
      {
        name: '',
        link: '/legal-service/dashboard',
        icon: HomeOutlinedIcon,
        tooltip: 'Dashboard'
      },
      {
        name: '',
        link: 'legal-service/users',
        icon: GroupsOutlinedIcon,
        tooltip: 'Users'
      },
      {
        name: '',
        link: '/legal-service/proposal-list',
        icon: PlaylistAddCheckOutlinedIcon,
        tooltip: 'Proposals'
      },
      {
        name: '',
        link: 'legal-service/template-bank/list',
        icon: AccountBalanceIcon,
        tooltip: 'Templates Bank'
      },
      {
        name: '',
        link: '/legal-service/analytics',
        icon: InsertChartOutlinedRoundedIcon,
        tooltip: 'Analytics'
      },
      {
        name: '',
        link: '/legal-service/help-center',
        icon: HelpIcon,
        tooltip: 'Help Centre'
      }
    ]
  }
];

export const corporateMenuItems: MenuItems[] = [
  {
    items: [
      {
        name: '',
        link: '/corporate/dashboard',
        icon: HomeOutlinedIcon,
        tooltip: 'Dashboard'
      },
      {
        name: '',
        link: '/corporate/users',
        icon: GroupsOutlinedIcon,
        tooltip: 'Users'
      },
      {
        name: '',
        link: '/corporate/analytics',
        icon: InsertChartOutlinedRoundedIcon,
        tooltip: 'Analytics'
      },
      {
        name: '',
        link: '/corporate/list-review',
        icon: MiscellaneousServicesIcon,
        tooltip: 'Services Review'
      },
      {
        name: '',
        link: '/corporate/rfp/create/conflict',
        icon: HowToRegIcon,
        tooltip: 'Draft New RFP'
      },
      {
        name: '',
        link: '/corporate/template-bank/list',
        icon: FileCopyIcon,
        tooltip: 'Templates Bank'
      },
      {
        name: '',
        link: '/corporate/rfp-list',
        icon: PlaylistAddCheckOutlinedIcon,
        tooltip: 'RFPs List'
      },
      {
        name: '',
        link: '/corporate/preferred-law-firms',
        icon: BookmarkAddSharpIcon,
        tooltip: 'Preferred/Panel Law Firms'
      },
      {
        name: '',
        link: '/corporate/help-center',
        icon: HelpIcon,
        tooltip: 'Help Centre'
      }
    ]
  }
];

export const gaMenuModel: GaMenuItems[] = [
  {
    items: [
      {
        name: '',
        tooltip: 'Dashboard',
        link: '/',
        icon: HomeOutlinedIcon
      },

      {
        name: '',
        tooltip: 'Corporate',
        link: 'admin/corporate',
        icon: CorporateFareIcon
      },
      {
        name: '',
        tooltip: 'Legal Services Providers',
        link: 'admin/legal-services-providers',
        icon: GavelIcon
      },
      {
        name: '',
        tooltip: 'Payments',
        link: 'admin/financials',
        icon: MonetizationOnIcon
      },
      {
        name: '',
        tooltip: 'RFPs List',
        link: 'admin/all-rfp-list',
        icon: HowToRegIcon
      }
    ]
  }
];
