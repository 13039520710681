import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useNotification from 'src/hooks/useNotification';
import { createSession, getUser } from 'src/slices/user';

const Callback = () => {
  const { user, getIdTokenClaims, isLoading } = useAuth0();
  const [sendNotification] = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialCall = async () => {
      if (isLoading) return;

      if (!user) {
        navigate('/');
        return;
      }

      try {
        const accessToken = await getIdTokenClaims();
        if (!accessToken) {
          return;
        }
        localStorage.setItem('idToken', accessToken.__raw);
        const userId = accessToken.sub.split('|')[1];
        localStorage.setItem('loggedUserId', userId);
        await dispatch<any>(getUser({ userId: userId }));
        const sessionStorage = localStorage.getItem('sessionStorage');
        if (sessionStorage) {
          navigate('/');
          return;
        }
        let userDetails = JSON.parse(localStorage.getItem('userDetail'));
        const sessionIds = {
          token: `Bearer ${accessToken.__raw}`,
          corporate_id: userDetails?.corporate_id,
          user_id: userDetails?._id
        };
        const res = await dispatch<any>(createSession({ sessionIds: sessionIds }));
        if (res?.error) {
          navigate('/');
          sendNotification({ message: res?.payload?.message, variant: 'error' });
        }
        localStorage.setItem('sessionStorage', JSON.stringify(res?.payload?.detail));
        navigate('/');
      } catch (error) {
        sendNotification({ message: error.message, variant: 'error' });
        navigate('/');
      }
    };

    initialCall();
  }, [user, isLoading]);

  return null;
};

export default Callback;
