export const allowedFileTypes = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'video/mp4'
];

export const allowedImgTypes = ['image/png', 'image/jpeg', 'image/jpg'];

export const allowedVideoTypes = ['video/mp4'];

// export const allowedDocsTypes = ['application/msword', 'application/pdf'];
export const allowedDocsTypes = ['application/msword', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
