import { Backdrop, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FC } from 'react';

interface CommonLoaderModel {
  isLoading: boolean;
  loadWithText?: string;
}

const CommonLoader: FC<CommonLoaderModel> = ({ isLoading, loadWithText }) => {
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
        <Typography
          sx={{
            ml: 1
          }}
        >
          {loadWithText || ''}
        </Typography>
      </Backdrop>
    </div>
  );
};

CommonLoader.propTypes = {
  isLoading: PropTypes.bool
};

export default CommonLoader;
