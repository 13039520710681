import { RouteObject } from 'react-router';
import Authenticated from 'src/components/Authenticated';
import Callback from 'src/content/pages/Callback';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import { ProposalLuRoutes, ProposalsRoutes, lsDashboardsRoutes, ProposaMtRoutes } from './paths';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';

const luRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),

    children: [
      {
        path: '/',
        children: lsDashboardsRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: 'legal-service/proposals',
        children: ProposalsRoutes
      },
      {
        path: 'legal-service/template-bank',
        children: ProposalLuRoutes
      },
      {
        path: 'legal-service/master-template',
        children: ProposaMtRoutes
      }
    ]
  },
  {
    path: 'callback',
    element: <Callback />
  }
];

export default luRoutes;
