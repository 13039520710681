import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorHandler } from '../utils/errorHandler';
import { apiPost, apiGet, apiPut } from 'src/utils/axios';
import {
  postRFP,
  getRFPStatus,
  getBidders,
  postRfpUpload,
  getRfpListById,
  putRFP,
  getRFPListStatus,
  postRfpWinner,
  postCreateTemplateUrl,
  getTemplateUrl,
  postRfpWinnerPaymentUrl,
  postRfpFileCloneUrl
} from 'src/utils/pathConst';
import { RfpFormTypes } from 'src/content/cuModule/Rfp/Modal/FormModel/RfpFormModel';
import { BiddersResponseTypes, RfpStatusListModel, RfpStatusResponseTypes, RfpTemplateCreateModal, RfpUploadModal, RfpWinnerModal } from 'src/models/rfp';

export type ErrorInformation = {
  message: string;
  status: number;
};

type RfpState = {
  loading: boolean;
  error: ErrorInformation;
  rfp: RfpFormTypes;
  rfpStatus: RfpStatusResponseTypes;
  rfpListStatus: RfpStatusListModel;
  biddersList: object;
  rfpListById: object;
};

const initialState: RfpState = {
  loading: true,
  error: null,
  rfp: null,
  rfpStatus: null,
  rfpListStatus: null,
  biddersList: null,
  rfpListById: null
};

export const postRfp = createAsyncThunk<
  RfpFormTypes,
  { rfp },
  {
    rejectValue: ErrorInformation;
  }
>('post/postUploadLogo', async ({ rfp }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postRFP}`, rfp);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putRfp = createAsyncThunk<
  RfpFormTypes,
  { queryParam; rfp },
  {
    rejectValue: ErrorInformation;
  }
>('post/postUploadLogo', async ({ queryParam, rfp }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${putRFP}${queryParam.corporate_id}/${queryParam.rfp_id}`, rfp);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getRfpStatus = createAsyncThunk<
  RfpStatusResponseTypes,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getRfpStatus', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getRFPStatus}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const getRfpListStatus = createAsyncThunk<
  RfpStatusListModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getRfpListStatus', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getRFPListStatus}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getBiddersList = createAsyncThunk<
  BiddersResponseTypes,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getBiddersList', async ({ queryParam }, { rejectWithValue }) => {
  // const jurisdiction = new URLSearchParams(queryParam.jurisdiction).toString();
  // const pricingModel = new URLSearchParams(queryParam.pricing_model).toString();
  try {
    const getResponse = await apiGet(
      `${getBidders}?jurisdiction=[${queryParam.jurisdiction}]&pricing_model=[${queryParam.pricing_model}]&search_string=${queryParam.search_string}&offset=${queryParam.offset}&limit=${queryParam.limit}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getRfpListDetails = createAsyncThunk<
  RfpFormTypes,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getRfpListDetails', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getRfpListById}${queryParam.corporate_id}/${queryParam.rfp_id}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postRfpFileUpload = createAsyncThunk<
  RfpUploadModal,
  { file },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/postLsFileUpload', async ({ file }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postRfpUpload}`, file);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postRfpFileClone = createAsyncThunk<
  RfpUploadModal,
  { params },
  {
    rejectValue: ErrorInformation;
  }
>('LegalServiceConnect/postLsFileUpload', async ({ params }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postRfpFileCloneUrl}`, params);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postCreateTemplate = createAsyncThunk<
  RfpTemplateCreateModal,
  { requestData: any },
  {
    rejectValue: ErrorInformation;
  }
>('postCreateTemplate', async ({ requestData }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postCreateTemplateUrl}`, requestData);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postRfpWinners = createAsyncThunk<
  RfpWinnerModal,
  { params: { rfp_id: string }; requestData: any },
  {
    rejectValue: ErrorInformation;
  }
>('postRfpWinners', async ({ params, requestData }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postRfpWinner}/${params.rfp_id}`, requestData);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postRfpPaymentSubmit = createAsyncThunk<
  any,
  { requestData: any },
  {
    rejectValue: ErrorInformation;
  }
>('postRfpPaymentSubmit', async ({ requestData }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postRfpWinnerPaymentUrl}`, requestData);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getRfpListTemplateDetails = createAsyncThunk<
  RfpFormTypes,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getRfpListTemplateDetails', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getTemplateUrl}?search_string=${queryParam.search_string}&limit=${queryParam.limit}&offset=${queryParam.offset}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getTemplateDetailsById = createAsyncThunk<
  RfpFormTypes,
  { queryParam },
  {
    rejectValue: ErrorInformation;
  }
>('select/getTemplateDetailsBy', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getTemplateUrl}/${queryParam.templateId}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const rfpSlice = createSlice({
  name: 'rfp',
  initialState,
  reducers: {
    handleResetRfpDetail: (state) => {
      state.rfpListById = null;
    }
  },
  extraReducers: {
    //post rfp information
    [postRfp.pending.type]: (state) => {
      state.loading = true;
    },
    [postRfp.fulfilled.type]: (state, action: PayloadAction<RfpFormTypes | null>) => {
      state.rfp = action.payload;
      state.loading = false;
    },
    [postRfp.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //post rfp information
    [putRfp.pending.type]: (state) => {
      state.loading = true;
    },
    [putRfp.fulfilled.type]: (state, action: PayloadAction<RfpFormTypes | null>) => {
      state.rfp = action.payload;
      state.loading = false;
    },
    [putRfp.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //post rfp status information
    [getRfpStatus.pending.type]: (state) => {
      state.loading = true;
    },
    [getRfpStatus.fulfilled.type]: (state, action: PayloadAction<RfpStatusResponseTypes | null>) => {
      state.rfpStatus = action.payload;
      state.loading = false;
    },
    [getRfpStatus.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    //get rfp status dropdown
    [getRfpListStatus.pending.type]: (state) => {
      state.loading = true;
    },
    [getRfpListStatus.fulfilled.type]: (state, action: PayloadAction<RfpStatusListModel | null>) => {
      state.rfpListStatus = action.payload;
      state.loading = false;
    },
    [getRfpListStatus.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    //get bidders information
    [getBiddersList.pending.type]: (state) => {
      state.loading = true;
    },
    [getBiddersList.fulfilled.type]: (state, action: PayloadAction<RfpStatusResponseTypes | null>) => {
      state.biddersList = action.payload;
      state.loading = false;
    },
    [getBiddersList.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    //get bidders information
    [getRfpListDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [getRfpListDetails.fulfilled.type]: (state, action: PayloadAction<RfpFormTypes | null>) => {
      state.rfpListById = action.payload;
      state.loading = false;
    },
    [getRfpListDetails.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});
export const { handleResetRfpDetail } = rfpSlice.actions;
