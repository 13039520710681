/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { alpha, Box, Grid, List, styled, Typography, Avatar, Button } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';
import SidebarMenuItem from './item';
import { corporateMenuItems, gaMenuModel, MenuItem, menuItems } from './items';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useAuth0 } from '@auth0/auth0-react';
import { COLOR } from 'src/components/Styles/Variables/Variables';
import { useDispatch } from 'react-redux';
import { deleteSession } from 'src/slices/user';
import useNotification from 'src/hooks/useNotification';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0.5)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[100]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.white};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.yellow[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.yellow[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);
const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

// eslint-disable-next-line no-undef
const renderSidebarMenuItems = ({ items, path }: { items: MenuItem[]; path: string }): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce(
        (ev, item) =>
          reduceChildRoutes({
            ev,
            item,
            path
          }),
        []
      )}
    </List>
  </SubMenuWrapper>
);

// eslint-disable-next-line no-undef
const reduceChildRoutes = ({ ev, path, item }: { ev: JSX.Element[]; path: string; item: MenuItem }): Array<JSX.Element> => {
  const key = item.name;
  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem key={key} active={exactMatch} name={item.name} link={item.link} badge={item.badge} badgeTooltip={item.badgeTooltip} icon={item.icon} />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [sendNotification] = useNotification();
  const { logout } = useAuth0();
  const { users } = useSelector((state: any) => state.user);
  const sessionStorage = JSON.parse(localStorage.getItem('sessionStorage'));

  useEffect(() => {
    // dispatch<any>(getUser({}));
  }, []);

  const handleLogout = async (): Promise<void> => {
    try {
      const sessionIds = {
        session_id: sessionStorage?._id
      };
      let res = await dispatch<any>(deleteSession({ sessionIds: sessionIds }));
      if (res?.error) {
        sendNotification({ message: res?.payload?.message, variant: 'error' });
      } else {
        logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI
          }
        });
        localStorage.clear();
      }
    } catch (err) {
      return err;
    }
  };

  const renderAdminMenu = () => {
    return gaMenuModel.map((section, index) => (
      <div key={index}>
        <MenuWrapper
          key={index}
          sx={{
            pt: {
              md: 5,
              lg: 5,
              xl: 5
            }
          }}
        >
          <List component="div">
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
      </div>
    ));
  };

  const renderCorporateMenu = () => {
    return corporateMenuItems.map((section, index) => (
      <div key={index}>
        <MenuWrapper
          key={index}
          sx={{
            pt: {
              md: 5,
              lg: 5,
              xl: 5
            }
          }}
        >
          <List component="div">
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
      </div>
    ));
  };

  const renderLegalServiceMenu = () => {
    return menuItems.map((section, index) => (
      <div key={index}>
        <Grid>
          <MenuWrapper
            key={index}
            sx={{
              pt: {
                md: 5,
                lg: 5,
                xl: 5
              }
            }}
          >
            <List component="div">
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname
              })}
            </List>
          </MenuWrapper>
        </Grid>
      </div>
    ));
  };

  return (
    <>
      <Grid>
        <MenuWrapper
          sx={{
            pt: {
              md: 5,
              lg: 5,
              xl: 5
            },
            display: {
              xs: 'block',
              sm: 'block',
              md: 'none',
              lg: 'none',
              xl: 'none'
            }
          }}
        >
          <List component="div">
            <Avatar
              variant="rounded"
              sx={{
                width: '50%',
                height: '100%',
                backgroundColor: 'transparent',
                margin: 'auto'
              }}
              alt={users?.detail.first_name}
              src={users?.detail.logo}
            />
            <UserBoxText>
              <Typography sx={{ color: 'white', my: 1, textAlign: 'center' }} variant="body1">
                {users?.detail.first_name} {users?.detail.surname}
              </Typography>
            </UserBoxText>
          </List>
        </MenuWrapper>
        {users?.detail?.role === 'GA' && renderAdminMenu()}
        {users?.detail?.role === 'CU' && renderCorporateMenu()}
        {/* {users?.detail?.role === 'CU' && users?.detail?.is_primary_user === true
          ? renderCorporateMenu()
          : users?.detail?.role === 'CU' && users?.detail?.is_primary_user === false
          ? renderCorporateMenuForInvitedUser()
          : null} */}

        {users?.detail?.role === 'LS' && renderLegalServiceMenu()}
        <Grid
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'none',
              lg: 'none',
              xl: 'none'
            }
          }}
        >
          <Button
            sx={{
              background:
                users.detail.role === 'GA'
                  ? COLOR.btnBg1
                  : users.detail.role === 'CU'
                  ? COLOR.btnBg1
                  : users.detail.role === 'LS'
                  ? COLOR.btnBg2
                  : COLOR.btnBg1,
              mt: 0.5,
              mx: 4,
              color: users.detail.role === 'GA' || users.detail.role === 'LS' ? 'white' : 'black',
              ':hover': {
                background:
                  users.detail.role === 'GA'
                    ? COLOR.btnBg1
                    : users.detail.role === 'CU'
                    ? COLOR.btnBg1
                    : users.detail.role === 'LS'
                    ? COLOR.btnBg2
                    : COLOR.btnBg1
              }
            }}
            // fullWidth
            onClick={handleLogout}
          >
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            Sign out
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default SidebarMenu;
