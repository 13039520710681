/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { themeCreator } from './base';
import { useAuth0 } from '@auth0/auth0-react';

export const ThemeContext = React.createContext((themeName: string): void => {});

const ThemeProviderWrapper: React.FC = (props) => {
  const { isAuthenticated } = useAuth0();
  const curThemeName = isAuthenticated ? 'GreyGooseTheme' : 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {};

  useEffect(() => {
    _setThemeName(curThemeName);
  }, [curThemeName]);
  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
