import { lazy, Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Callback from 'src/content/pages/Callback';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Dashboard = Loader(lazy(() => import('src/content/gaModule/Dashboard')));
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Corporate = Loader(lazy(() => import('src/content/gaModule/corporate')));
const LegalServices = Loader(lazy(() => import('src/content/gaModule/legalServices')));
const Financials = Loader(lazy(() => import('src/content/gaModule/Financials')));

const gaDashboardsRoutes = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: 'admin/dashboard',
    element: <Dashboard />
  },
  {
    path: 'admin/corporate',
    element: <Corporate />
  },
  {
    path: 'admin/legal-services-providers',
    element: <LegalServices />
  },
  {
    path: 'admin/financials',
    element: <Financials />
  },
  {
    path: 'callback',
    element: <Callback />
  },
  {
    path: '/status404',
    element: <Status404 />
  }
];

export default gaDashboardsRoutes;
