export const COLOR = {
  white: '#FFFFFF',
  black: '#000000',
  danger: '#F44336',
  success: '#70d082',
  lightGreen: '#ccff90',
  accept: 'rgb(238,250,232)',
  decline: 'rgb(255,232,236)',
  awaiting: 'rgb(234,249,255)',
  info: 'rgb(255,246,235)',
  inputColor: '#000',
  light: '#666666',
  btnBg1: '#f9dc72',
  btnBg2: '#E39756',
  CU_signup_card1: '#f9dc72',
  CU_signup_card2: '#E39756',
  CU_dashboard_bg: '#DFE5EB',
  NV_User_color: 'rgb(127,127,132)',
  grey: 'grey',
  blue: 'blue',
  LSColor: '#1C1E21',
  LSbgColor: 'rgb(227,151,86)'
};

export const FONTWEIGHT = {
  fw_100: 100,
  fw_200: 200,
  fw_300: 300,
  fw_400: 400,
  fw_500: 500,
  fw_600: 600,
  fw_700: 700,
  fw_800: 800,
  fw_900: 900
};

export const FONTSIZE = {
  fs_10: 10,
  fs_12: 12,
  fs_14: 14,
  fs_16: 16,
  fs_18: 18,
  fs_20: 20,
  fs_22: 22,
  fs_24: 24,
  fs_26: 26,
  fs_28: 28,
  fs_30: 30
};
