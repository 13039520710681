import { lazy, Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Callback from 'src/content/pages/Callback';
import Status404 from 'src/content/pages/Status/Status404';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Dashboard = Loader(lazy(() => import('src/content/luModule/dashboard')));
const Users = Loader(lazy(() => import('src/content/luModule/Users/index')));
const Proposals = Loader(lazy(() => import('src/content/luModule/Proposals/index')));
const ProposalList = Loader(lazy(() => import('src/content/luModule/ListProposals/index')));
const ComimgSoon = Loader(lazy(() => import('src/components/_shared/ComingSoon')));
const Payments = Loader(lazy(() => import('src/content/luModule/payment/paymentspage')));
const Success = Loader(lazy(() => import('src/content/luModule/payment/successPage')));
const Submit = Loader(lazy(() => import('src/content/luModule/Proposals/SubmitForm')));
const Analytics = Loader(lazy(() => import('src/content/luModule/Analytics/Analytics')));
const Accountpreview = Loader(lazy(() => import('src/content/luModule/MyAccount/AccountDetails')));
const FAQ = Loader(lazy(() => import('src/content/luModule/FAQ/FAQ')));
const HelpCenter = Loader(lazy(() => import('src/content/luModule/FAQ/HelpCenter')));
const FAQCenter = Loader(lazy(() => import('src/content/luModule/FAQ/FaqCenter')));
const TemplateCreate = Loader(lazy(() => import('src/content/luModule/TemplateBank/index')));
const TemplateList = Loader(lazy(() => import('src/content/luModule/TemplateBank/listTemplate')));
const SelectTemplateList = Loader(lazy(() => import('src/content/luModule/TemplateBank/SelectTemplateList')));
const MasterTemplate = Loader(lazy(() => import('src/content/luModule/MasterTemplate/index')));
const UserGroup = Loader(lazy(() => import('src/content/luModule/UserGroup/index')));
const AddUserGroup = Loader(lazy(() => import('src/content/luModule/UserGroup/Create')));
const LspGroup = Loader(lazy(() => import('src/content/luModule/UserGroup/CreateMember')));

export const lsDashboardsRoutes = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: 'legal-service/dashboard',
    element: <Dashboard />
  },
  {
    path: 'legal-service/analytics',
    element: <Analytics />
  },
  {
    path: 'legal-service/users',
    element: <Users />
  },
  {
    path: 'legal-service/legalservices',
    element: <ComimgSoon />
  },
  {
    path: 'legal-service/user-group',
    element: <UserGroup />
  },
  {
    path: 'legal-service/user-group/add-user-group',
    element: <AddUserGroup />
  },
  {
    path: 'legal-service/user-group/add-user-group/legal-service-provider-group',
    element: <LspGroup />
  },
  {
    path: 'legal-service/proposal-in-progress',
    element: <ComimgSoon />
  },
  {
    path: 'legal-service/faq',
    element: <FAQ />
  },
  {
    path: 'legal-service/help-center',
    element: <HelpCenter />
  },
  {
    path: 'legal-service/faq-center',
    element: <FAQCenter />
  },
  {
    path: 'legal-service/proposal-list',
    element: <ProposalList />
  },
  {
    path: '/legal-service/payments',
    element: <Payments />
  },
  {
    path: 'legal-service/payments/success',
    element: <Success />
  },
  {
    path: 'legal-service/submit',
    element: <Submit />
  },
  {
    path: 'legal-service/account-preview',
    element: <Accountpreview />
  },
  {
    path: 'legal-service/template-bank/list',
    element: <TemplateList />
  },
  {
    path: 'legal-service/template-bank/select-list',
    element: <SelectTemplateList />
  },
  {
    path: 'callback',
    element: <Callback />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: '/comingsoon',
    element: <ComimgSoon />
  }
];
export const ProposalsRoutes = [
  {
    path: '',
    element: <Proposals />
  },
  {
    path: 'conflictcheck',
    element: <Proposals />
  },
  {
    path: 'preliminaryinformation',
    element: <Proposals />
  },

  {
    path: 'scopeofwork',
    element: <Proposals />
  },
  {
    path: 'pricing',
    element: <Proposals />
  },
  {
    path: 'otherkeyinformation',
    element: <Proposals />
  },
  {
    path: 'preview',
    element: <Proposals />
  }
];
export const ProposalLuRoutes = [
  {
    path: 'proposals',
    element: <TemplateCreate />
  },
  {
    path: 'proposals/conflictcheck',
    element: <TemplateCreate />
  },
  {
    path: 'proposals/preliminaryinformation',
    element: <TemplateCreate />
  },
  {
    path: 'proposals/scopeofwork',
    element: <TemplateCreate />
  },
  {
    path: 'proposals/pricing',
    element: <TemplateCreate />
  },
  {
    path: 'proposals/OtherKeyInformation',
    element: <TemplateCreate />
  },
  {
    path: 'proposal/preview',
    element: <TemplateCreate />
  },
  {
    path: 'proposal/submit',
    element: <TemplateCreate />
  }
];
export const ProposaMtRoutes = [
  {
    path: '',
    element: <MasterTemplate />
  },
  {
    path: 'conflictcheck',
    element: <MasterTemplate />
  },
  {
    path: 'preliminaryinformation',
    element: <MasterTemplate />
  },
  {
    path: 'scopeofwork',
    element: <MasterTemplate />
  },
  {
    path: 'pricing',
    element: <MasterTemplate />
  },
  {
    path: 'otherkeyinformation',
    element: <MasterTemplate />
  },
  {
    path: 'preview',
    element: <MasterTemplate />
  }
];
