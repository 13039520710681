import { Component, ErrorInfo } from 'react';
import SomethingWentWrong from './SomethingWentWrong';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can log the error here
    console.error('Error caught by error boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // return <div>Something went wrong.</div>;
      return (
        <>
          <SomethingWentWrong />
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
