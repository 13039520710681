import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button } from '@mui/material';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';

function LoginAuth0() {
  const [error, setError] = useState<string | null>(null);
  const isMountedRef = useRefMounted();
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async (): Promise<void> => {
    try {
      await loginWithRedirect({
        appState: {
          returnTo: '/'
        },
        authorizationParams: {
          prompt: 'login'
        }
      });
    } catch (err) {
      if (isMountedRef.current) {
        setError(err.message);
      }
    }
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <Button
        fullWidth
        onClick={handleLogin}
        size="large"
        sx={{
          py: 2
        }}
        variant="outlined"
      >
        Sign In
      </Button>
    </>
  );
}

export default LoginAuth0;
