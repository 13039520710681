import { useContext } from 'react';

import { SidebarContext } from 'src/contexts/SidebarContext';

import { alpha, Box, darken, Divider, Drawer, lighten, styled, useTheme } from '@mui/material';

import Scrollbar from 'src/components/Scrollbar';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 80px;
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 61px;
        font-size: 20px
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0
        }}
      >
        <SidebarWrapper
          sx={{
            display: {
              xs: 'none',
              md: 'inline-block'
            },
            position: 'fixed',
            left: 0,
            top: 0,
            background: theme.palette.mode === 'dark' ? alpha(lighten(theme.header.background, 0.1), 0.5) : darken(theme.colors.alpha.black[100], 0.5),
            boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
          }}
        >
          <Scrollbar>
            <Box mt={3} mb={3}>
              <Box
                mx={2}
                sx={{
                  width: 52
                }}
              ></Box>
            </Box>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Box>

      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            display: {
              xs: 'none',
              md: 'inline-block'
            },
            position: 'fixed',
            left: 0,
            top: 0,
            background: theme.palette.mode === 'dark' ? alpha(lighten(theme.header.background, 0.1), 0.5) : darken(theme.colors.alpha.black[100], 0.5),
            boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52
                }}
              ></Box>
            </Box>
            <Divider
              sx={{
                my: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
