import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Callback from 'src/content/pages/Callback';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const NewHomePage = Loader(lazy(() => import('src/components/LandingPage/Layout/index')));
const RegisterFormStepOne = Loader(lazy(() => import('src/content/pages/Auth/Signup/Corporate/Form/RegisterFormStepOne')));
const RegisterFormStepTwo = Loader(lazy(() => import('src/content/pages/Auth/Signup/Corporate/Form/RegisterFormStepTwo')));
const Invite = Loader(lazy(() => import('src/content/pages/Auth/Signup/Legalservice/Form/Invite')));
const Learning = Loader(lazy(() => import('src/content/pages/Auth/Signup/Legalservice/Form/Learning')));
const LearningOrgIndustries = Loader(lazy(() => import('src/content/pages/Auth/Signup/Legalservice/Form/LearningOrgIndustries')));
const LearningOrgPractices = Loader(lazy(() => import('src/content/pages/Auth/Signup/Legalservice/Form/LearningOrgPractices')));
const LearningOrgJurisdictions = Loader(lazy(() => import('src/content/pages/Auth/Signup/Legalservice/Form/LearningOrgJurisdictions')));
const LearningOrgPricing = Loader(lazy(() => import('src/content/pages/Auth/Signup/Legalservice/Form/LearningOrgPricing')));
const LearningOrgFile = Loader(lazy(() => import('src/content/pages/Auth/Signup/Legalservice/Form/LearningOrgFile')));
const ZavenHire = Loader(lazy(() => import('src/components/LandingPage/Components/ZavanHire')));
const ZavenAnalytics = Loader(lazy(() => import('src/components/LandingPage/Components/ZavanAnalytics')));
const NavanKnowledge = Loader(lazy(() => import('src/components/LandingPage/Components/navanKnowledge')));
const ComimgSoon = Loader(lazy(() => import('src/components/_shared/ComingSoon')));
const AboutUs = Loader(lazy(() => import('src/components/LandingPage/Components/AboutUs')));
const Testimonials = Loader(lazy(() => import('src/components/LandingPage/Components/Testimonials')));
const HelpCenter = Loader(lazy(() => import('src/components/LandingPage/Components/HelpCenter')));
const FAQ = Loader(lazy(() => import('src/components/LandingPage/Components/FAQ')));
const PrivacyPolicy = Loader(lazy(() => import('src/components/LandingPage/Components/PrivacyPolicy')));
const UnAuthorized = Loader(lazy(() => import('src/content/pages/Status/Status401')));

const publicRoutes: RouteObject[] = [
  {
    path: '',
    element: <NewHomePage />
  },
  {
    path: '/home',
    element: <NewHomePage />
  },
  {
    path: 'zaven-rfp',
    element: <ZavenHire />
  },
  {
    path: 'zaven-analytics',
    element: <ZavenAnalytics />
  },
  {
    path: 'zaven-knowledge',
    element: <NavanKnowledge />
  },
  {
    path: 'about-us',
    element: <AboutUs />
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: 'testimonials',
    element: <Testimonials />
  },
  {
    path: 'faq-center',
    element: <HelpCenter />
  },
  {
    path: 'faq',
    element: <FAQ />
  },
  {
    path: 'unauthorized',
    element: <UnAuthorized />
  },
  {
    path: 'sign-up/corporate/step1',
    element: <RegisterFormStepOne />
  },
  {
    path: 'sign-up/corporate/step2',
    element: <RegisterFormStepTwo />
  },
  {
    path: 'sign-up/legalservice/invite',
    element: <Invite />
  },
  {
    path: 'sign-up/legalservice/learning',
    element: <Learning />
  },
  {
    path: 'sign-up/legalservice/learning-org-industries',
    element: <LearningOrgIndustries />
  },
  {
    path: 'sign-up/legalservice/learning-org-practices',
    element: <LearningOrgPractices />
  },
  {
    path: 'sign-up/legalservice/learning-org-jurisdictions',
    element: <LearningOrgJurisdictions />
  },
  {
    path: 'sign-up/legalservice/learning-org-pricing',
    element: <LearningOrgPricing />
  },
  {
    path: 'sign-up/legalservice/learning-org-file',
    element: <LearningOrgFile />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'comingsoon',
    element: <ComimgSoon />
  },
  {
    path: 'callback',
    element: <Callback />
  }
];

export default publicRoutes;
