import { Box, styled } from '@mui/material';
import Admin from 'src/asserts/images/png/ZavanBlack.png';
import Corporate from 'src/asserts/images/png/ZavenCorporate.png';
import LegalServices from 'src/asserts/images/png/ZavenLegalservices.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LogoWrapper = styled(Box)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  padding: ${theme.spacing(0, 1, 0, 0)};
  display: flex;
  text-decoration: none;
  font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const { users } = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  return (
    <LogoWrapper>
      {users?.detail?.role === 'GA' && (
        <Box
          onClick={() => navigate('/')}
          component="img"
          sx={{
            maxWidth: {
              xs: '85%',
              sm: '20%',
              md: '15%',
              lg: '13%',
              xl: '13%'
            },
            mr: 1,
            cursor: 'pointer',
            ml: 2.5
          }}
          alt="Auth0"
          src={Admin}
        />
      )}
      {users?.detail?.role === 'CU' && (
        <Box
          onClick={() => navigate('/')}
          component="img"
          sx={{
            maxWidth: {
              xs: '85%',
              sm: '20%',
              md: '15%',
              lg: '13%',
              xl: '13%'
            },
            mr: 1,
            ml: 2.5,
            cursor: 'pointer'
          }}
          alt="Auth0"
          src={Corporate}
        />
      )}
      {users?.detail?.role === 'LS' && (
        <Box
          onClick={() => navigate('/')}
          component="img"
          sx={{
            maxWidth: {
              xs: '85%',
              sm: '20%',
              md: '15%',
              lg: '13%',
              xl: '13%'
            },
            mr: 1,
            ml: 2.5,
            cursor: 'pointer'
          }}
          alt="Auth0"
          src={LegalServices}
        />
      )}
    </LogoWrapper>
  );
}

export default Logo;
