import type { Action } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import userSlice from 'src/slices/user';
import selectSlice from 'src/slices/select';
import postSlice from 'src/slices/post';
import legalServiceSlice from 'src/slices/LegalServiceConnect';
import corporateSlice from 'src/slices/CorporatConnect';
import { rfpSlice } from 'src/slices/Rfp';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    select: selectSlice.reducer,
    post: postSlice.reducer,
    legalServiceSlice: legalServiceSlice.reducer,
    corporateSlice: corporateSlice.reducer,
    rfp: rfpSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<any>>;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
