import { Box, Grid, Typography } from '@mui/material';
import SomethingWentwrong from 'src/asserts/images/png/SomethingwentWrong.png';

const SomethingWentWrong = () => {
  return (
    <Box>
      <Grid>
        <Grid>
          <Box
            component="img"
            sx={{
              width: '50%',
              height: '50%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center'
            }}
            src={SomethingWentwrong}
            alt="Something Went Wrong"
          />
        </Grid>
        <Grid>
          <Typography sx={{ textAlign: 'center', my: 3, fontSize: '25px', fontWeight: 'bold' }}>Oops Something went wrong</Typography>
          <Typography sx={{ textAlign: 'center', my: 2 }}>Sorry, we cant process your request at the moment.</Typography>
          <Typography sx={{ textAlign: 'center', my: 1 }}>Please try again later.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SomethingWentWrong;
